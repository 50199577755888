import ReactOnRails from "react-on-rails";

import Navigation from "../bundles/commons/Navigation";
import Dashboard from "../bundles/Dashboard";
import PersonalDashboard from "../bundles/Dashboard/Personal";
import Notebook from "../bundles/Notebook";
import NotebookNew from "../bundles/Notebook/New";
import GeniusWriter from "../bundles/GeniusWriter";
import Pdp from "../bundles/Pdp";
import DataLab from "../bundles/DataLab";
import Chat from "../bundles/Chat";
import TeamsIndex from "../bundles/Team/Index";
import TeamInit from "../bundles/Team/Init";
import UsersIndex from "../bundles/Users/Index";
import RegistrationsNew from "../bundles/Devise/Registrations/New";
import CompanyNew from "../bundles/Company/New";
import OnboardingNew from "../bundles/Onboarding/New";
import TeamStageNew from "../bundles/TeamStage/New";
import IntegrationsNew from "../bundles/Integrations/New";
import Confirmation from "../bundles/SurveyKpi/Confirmation";
import AllSet from "../bundles/Responses/AllSet";
import Plans from "../bundles/Plans";
import PlansProcessing from "../bundles/Plans/Processing";
import PlansNewUser from "../bundles/Plans/NewUser";
import PlansWebsite from "../bundles/Plans/Website";
import PersonalSettings from "../bundles/Settings/Personal";
import LanguageSettings from "../bundles/Settings/Language";
import IntegrationsSettings from "../bundles/Settings/Integrations";

ReactOnRails.register({
  Navigation,
  Dashboard,
  PersonalDashboard,
  Notebook,
  NotebookNew,
  GeniusWriter,
  Pdp,
  DataLab,
  TeamsIndex,
  TeamInit,
  UsersIndex,
  RegistrationsNew,
  CompanyNew,
  OnboardingNew,
  TeamStageNew,
  IntegrationsNew,
  Confirmation,
  AllSet,
  Chat,
  Plans,
  PlansProcessing,
  PlansNewUser,
  PlansWebsite,
  PersonalSettings,
  LanguageSettings,
  IntegrationsSettings,
});

import I18n from "i18n-js";
window.I18n = I18n;
// I18n.locale = 'pt-BR'
console.debug("DEBUG application.js - locale: ", I18n.locale);
console.debug("DEBUG application.js - defaultLocale: ", I18n.defaultLocale);
console.debug("DEBUG application.js - hello: ", I18n.t("hello"));
