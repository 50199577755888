import React from "react";

import MenuItem from "./MenuItem";

const Dropdown = ({ show, submenu, depthLevel = 1 }) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul className={`dropdown ${dropdownClass} ${show ? "show" : ""}`}>
      {submenu && submenu.map((submenu, index) => (
        <MenuItem key={index} item={submenu} depthLevel={depthLevel} />
      ))}
    </ul>
  );
}

export default Dropdown;
