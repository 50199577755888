import React from "react";
import Progress from "react-svg-progress";
import { ReactSVG } from "react-svg";

const ActionButton = ({ className, title, icon, loading, disabled, onClick }) => {

  const handleOnClick = (event) => {
    event.preventDefault();
    if (!loading) { onClick && onClick(); }
  }

  return (
    <button
      className={`${className} action-button ${loading ? "loading" : ""}`}
      disabled={disabled}
      onClick={handleOnClick}
    >
      {title}
      {loading ? (
        <Progress color="#265F78" size="15px" />
      ) : icon ? (
        <ReactSVG className="icon" src={icon} />
      ) : null}
    </button>
  );
}

export default ActionButton;
